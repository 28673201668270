import axios from "axios";

export const headers = {
	"Tenant-Access-Token": "NGRkMzczNGNhZDdmMGU3NzFiYzNjMGQzOTVkNjJmNGU=",
	"Tenant-Access-Key": "ne21vVtsbOXplg",
};
export const BASE_URL = "https://immersive-game-india.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});

